// socketService.js
import { io } from 'socket.io-client';

const socket = io('https://socket.digipocket.io');

export const subscribeToOrderBook = (pair, callback) => {
  socket.emit('subscribe', pair);
  socket.on('orderbook-update', callback);
};

export const subscribeToTradeHistory = (pair, callback) => {
  socket.emit('subscribe-trade-history', pair);
  socket.on('trade-history-update', callback);
};

export const unsubscribeFromOrderBook = (pair) => {
  socket.emit('unsubscribe', pair);
};

export const disconnectSocket = () => {
  socket.disconnect();
};

export default socket; // Export the socket instance if needed elsewhere
